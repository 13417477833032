import React, { Component, createRef, Fragment } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty, map as _map, forEach as _forEach } from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import parse from "html-react-parser";

import { withRouter } from "../../../components/hoc/withRouter";

import { Modal, Select } from "../controls";
import { showAlertMessage } from "./AlertMessage";

import { IconSuccess, IconUpload, IconAttach, IconDelete } from "../../../helpers/iconStyles";
import { isValidEmail } from "../../../helpers/utils";

import { uploadReadFile } from "../../../services/enrich";

import { combinedLookupData, uploadReadFileSuccess, removeUploadedFile, resetRemoveUploadedFile, uploadReadFileError, saveUploadMappingData, resetDataMappingError, resetDataMappingConfig } from "../../../actions/enrich";
import { updateLoadingState, fetchUserSettings, fetchCreditDetails } from "../../../actions/application";

const dropzoneRef = createRef();

// const jobTypes = [
//   { id: 1, name: "Enrich" },
//   { id: 2, name: "Validate" },
//   { id: 3, name: "Enrich & Validate" },
// ];

class SingleDropzone extends Component {

  static defaultProps = { displayUploadedFiles: false };

  constructor(props) {
    super(props);

    this.defaultState = {
      fileUploadParam: null,
      selectedWorksheet: null,
      showWorksheetModal: false,
      mappingData: [],
      selectedFile: {},
      progress: 0,
      jobId: 1,
      stepNo: 1,
      isProcessCompleted: false,
      isDragOver: false,
      fileName: "",
      fileUrl: "",
      errors: {},
      errorMessage: "",
      thirdPartyErrors: [],
      otherErrors: [],
      successMessage: "",
      matchingPer: [],
      isSelectedAll: false,
      selectedSourcefield: [],
      allSelectedSourcefield: [],
      findResultAnyway: false,
      includeValidityCheck: false,
      includeCompanyMatchingScore: false,
      includeJobtitleScore: false,
      showReceiptModal: false,
      receiptList: "",
      addLoggedUserInEmail: false,
      maxEmployeesPerCompany: 50,
      maxAllowedEmployeesPerCompany: 1000,
    };

    this.defaultContactActivities = {
      include_posts: true,
      include_post_likes: false,
      include_post_comments: false,
      posts_limit: 1,
      post_comments_limit: 0,
      post_likes_limit: 0,
      totalEstimatedCredits: 0,
    }

    this.state = {
      ...this.defaultState,
      dataSource: [],
      contactActivities: _cloneDeep(this.defaultContactActivities)
    };
  }

  componentDidMount() {
    const { resetDataMappingConfig, combinedLookupData, data_source, mappingData, userDetails, fetchUserSettings, source, ccEmails } = this.props;

    if (typeof resetDataMappingConfig === "function") { resetDataMappingConfig(); }
    if (typeof combinedLookupData === "function" && (source !== "contact_posts")) { combinedLookupData(source); }
    if (typeof fetchUserSettings === "function") { fetchUserSettings(); }

    this.setState({
      dataSource: (data_source || {}),
      mappingData: (mappingData || []),
      fileName: _get(mappingData, "uploaded_file_result[0].filename", ""),
      fileUrl: _get(mappingData, "uploaded_file_result[0].url", ""),
      matchingPer: _get(userDetails, "data.search_matching_percentage", []),
      receiptList: (ccEmails || []).join(","),
      showReceiptModal: false,
      showWorksheetModal: false
    });
  }

  componentDidUpdate(prevProps, prevStates) {

    if (!_isEqual(prevProps.mappingData, this.props.mappingData)) {
      this.setState({
        mappingData: _get(this.props, "mappingData", []),
        fileName: _get(this.props, "mappingData.uploaded_file_result[0].filename", ""),
        fileUrl: _get(this.props, "mappingData.uploaded_file_result[0].url", ""),
        showWorksheetModal: false
      });
    }

    if (!_isEqual(prevProps.data_source, this.props.data_source)) {
      this.setState({ dataSource: _get(this.props, "data_source", {}) })
    }

    if (!_isEqual(prevProps.ccEmails, this.props.ccEmails)) {
      this.setState({ receiptList: _get(this.props, "ccEmails", []).join(",") })
    }

    if (!_isEqual(prevProps.uploadedFileURL, this.props.uploadedFileURL) && !_isEmpty(this.props.uploadedFileURL)) {
      this.setState({ fileUrl: this.props.uploadedFileURL });
    }

    if (!_isEqual(prevProps.successMessage, this.props.successMessage) && !_isEmpty(this.props.successMessage)) {

      this.setState({ successMessage: _get(this, "props.successMessage", ""), isProcessCompleted: true, showReceiptModal: false });

      if (typeof this.props.resetDataMappingError === "function") { this.props.resetDataMappingError(); }
      if (typeof this.props.fetchCreditDetails === "function") { this.props.fetchCreditDetails(); }
    }

    if (!_isEqual(prevProps.errors, this.props.errors) && !_isEmpty(this.props.errors)) {
      this._handleErrors();
    }

    if (!_isEqual(prevProps.removeUpload, this.props.removeUpload) && !_isEmpty(this.props.removeUpload)) {
      const { removeUpload, resetRemoveUploadedFile, resetDataMappingConfig, intl } = this.props;

      if ((removeUpload.status || null) === null) { return false; }

      if ((removeUpload.status || false) === true) {
        showAlertMessage((removeUpload.message || intl.formatMessage({ id: "message.file_removed_successfully", defaultMessage: "File removed successfully." })), "success");

        this.setState({ ...this.defaultState });
      } else {
        showAlertMessage((removeUpload.message || intl.formatMessage({ id: "message.file_removed_error", defaultMessage: "Something went wrong while removing file." })));
      }

      if (typeof resetRemoveUploadedFile === "function") { resetRemoveUploadedFile(); }
      if (typeof resetDataMappingConfig === "function") { resetDataMappingConfig(); }
    }

    if (!_isEqual(prevProps.userDetails, this.props.userDetails) && !_isEmpty(this.props.userDetails)) {
      const { userDetails } = this.props;

      if ((userDetails.status || null) === null) { return false; }

      if ((userDetails.status || false) === true) {

        this.setState({ matchingPer: _get(userDetails, "data.search_matching_percentage", []), });
      } else {
        showAlertMessage(_get(userDetails, "message", "Something went wrong while fetching user matching percentages."));
      }
    }
  }

  _handleErrors = () => {
    const { errors, resetDataMappingError } = this.props;

    const state = {};
    const statusCode = _get(errors, "statusCode", 401);
    const thirdPartyErrors = _get(errors, "errors.third_party_errors", []);
    const otherErrors = _get(errors, "errors.other_errors", []);
    let displayErrorMessage = true;

    if (statusCode === 413) {
      this.setState({ showWorksheetModal: true, errors: errors });
    } else {

      state.showWorksheetModal = false;
      state.showReceiptModal = false;

      if (!_isEmpty(thirdPartyErrors || [])) {
        state.thirdPartyErrors = _cloneDeep(thirdPartyErrors);
        displayErrorMessage = false;
      } else if (!_isEmpty(otherErrors || [])) {
        state.otherErrors = _cloneDeep(otherErrors);
      }

      if (displayErrorMessage === true) { state.errorMessage = _get(errors, "message", ""); }

      if (!_isEmpty(state)) { this.setState(state); }
    }

    if (typeof resetDataMappingError === "function") { resetDataMappingError(); }
  }

  _onDrop = async (acceptedFiles, fileRejections) => {
    const { intl, source = "", displayUploadedFiles = false, uploadReadFileSuccess, uploadReadFileError, updateLoadingState } = this.props;
    const { mappingData } = this.state;

    if (!_isEmpty(mappingData)) {
      showAlertMessage(intl.formatMessage({ id: "error.please_remove_uploaded_file", defaultMessage: "Please remove uploaded file." }));
      return false;
    }

    if (!_isEmpty(fileRejections) && (_get(fileRejections, "[0].errors[0].code", "") === "file-too-large")) {
      const maxFileSize = (Number(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE || 10000000) / 1000000);

      showAlertMessage(intl.formatMessage({ id: "error.max_file_size", defaultMessage: "File size should be less than {maxFileSize} MB." }, {
        maxFileSize: maxFileSize
      }));

      return false;
    }

    if (_isEmpty(acceptedFiles)) {
      showAlertMessage(intl.formatMessage({ id: "error.select_valid", defaultMessage: "Please select valid {field}." }, {
        field: intl.formatMessage({ id: "file", defaultMessage: "file" })
      }));

      return false;
    }

    const selectedFile = (acceptedFiles[0] || "");
    let data = new FormData();
    data.append("formfile", selectedFile);

    let state = { fileUploadParam: data, isDragOver: false };

    if (displayUploadedFiles === true) {
      state = { ...state, selectedFile: selectedFile, progress: 1 };
    }

    this.setState(state);

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const variables = { formfile: data, default_sheet_name: null, uploadType: source };

      const response = await uploadReadFile(variables, ({ progress = 0 }) => this.setState({ progress }));

      if ((response.flag || false) === true) {

        if (typeof uploadReadFileSuccess === "function") {
          uploadReadFileSuccess({ data: _get(response, "data", {}), message: _get(response, "message", "") });
        }
      } else {
        const statusCode = (response.statusCode || 403);

        if (typeof uploadReadFileError === "function") {
          uploadReadFileError({
            error: {
              title: _get(response, "title", "Alert"),
              message: _get(response, "message", "Something went wrong, Try again later."),
              errors: (response.error || {}),
              statusCode: (response.statusCode || 403),
              data: (response.data || {})
            }
          });
        }

        if ((displayUploadedFiles === true) && (statusCode !== 413)) { this.setState({ selectedFile: {}, progress: 1 }); }
      }
    } catch (error) {

      if (typeof uploadReadFileError === "function") {
        uploadReadFileError({
          error: {
            title: _get(error, "title", "Alert"),
            message: _get(error, "message", "Something went wrong, Try again later.")
          }
        });
      }

      if (displayUploadedFiles === true) { this.setState({ selectedFile: {}, progress: 1 }); }
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _onSubmitWorksheetName = async () => {
    const { source = "contact", displayUploadedFiles, uploadReadFileSuccess, uploadReadFileError, updateLoadingState, intl } = this.props;
    const { fileUploadParam, selectedWorksheet } = this.state;

    if (_isEmpty(selectedWorksheet)) {
      showAlertMessage(intl.formatMessage({ id: "message.error_worksheet", defaultMessage: "Please select worksheet first." }));
      return false;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const variables = { default_sheet_name: selectedWorksheet, formfile: fileUploadParam, uploadType: source };
      const response = await uploadReadFile(variables, ({ progress = 0 }) => this.setState({ progress }));

      if ((response.flag || false) === true) {

        if (typeof uploadReadFileSuccess === "function") {
          uploadReadFileSuccess({ data: _get(response, "data", {}), message: _get(response, "message", "") });
        }
      } else {

        if (typeof uploadReadFileError === "function") {
          uploadReadFileError({
            error: {
              title: _get(response, "title", "Alert"),
              message: _get(response, "message", "Something went wrong, Try again later."),
              errors: (response.error || {}),
              statusCode: (response.statusCode || 401),
              data: (response.data || {})
            }
          });
        }

        if (displayUploadedFiles === true) { this.setState({ progress: 1 }); }
      }
    } catch (error) {

      if (typeof uploadReadFileError === "function") {
        uploadReadFileError({
          error: {
            title: _get(error, "title", "Alert"),
            message: _get(error, "message", "Something went wrong, Try again later.")
          }
        });
      }

      if (displayUploadedFiles === true) { this.setState({ progress: 1 }); }
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _checkInArray = (value, arr) => {
    let foundIndex = false;

    _forEach(arr, (v, k) => {
      if (v.data_source_id === value) {
        foundIndex = k;
      }
    });

    return foundIndex;
  }

  _handleSubmit = (isRecipientOptional = false) => {
    const { saveUploadMappingData, source, intl } = this.props;
    const { mappingData, fileName, selectedSourcefield, findResultAnyway, includeValidityCheck, includeCompanyMatchingScore, includeJobtitleScore, receiptList, addLoggedUserInEmail, maxEmployeesPerCompany, maxAllowedEmployeesPerCompany, contactActivities } = this.state;

    if (_isEmpty(fileName)) {
      showAlertMessage(intl.formatMessage({ id: "error.enter", defaultMessage: "Please enter {field}." }, {
        field: intl.formatMessage({ id: "dropzone.file_name", defaultMessage: "file name" })
      }));
      return false;
    }

    const uploadedFileResult = _cloneDeep(mappingData.uploaded_file_result || []);
    const fuzzyMatched = _cloneDeep(mappingData.fuzzyMatched || []);
    let isDataFieldEmpty = false;

    if (_isEmpty(uploadedFileResult) || _isEmpty(fuzzyMatched)) {
      showAlertMessage(intl.formatMessage({ id: "enrich.contact.error_select_file", defaultMessage: "Please select file to complete request." }));
      return false;
    }

    if ((source !== "contact_posts") && _isEmpty(selectedSourcefield)) {
      showAlertMessage(intl.formatMessage({ id: "enrich.contact.error_select_field", defaultMessage: "Please select at least one field from the list." }));
      return false;
    }

    _forEach(selectedSourcefield, (sf, i) => {
      if (_isEmpty(sf.data_fields)) { isDataFieldEmpty = true; }
      sf.priority_sequence = i + 1;
    });

    if ((source !== "contact_posts") && isDataFieldEmpty === true) {
      showAlertMessage(intl.formatMessage({ id: "enrich.contact.error_select_field", defaultMessage: "Please select at least one field from the list." }));
      return false;
    }

    if ((source === "company_employees") && (["", 0].includes(maxEmployeesPerCompany))) {
      showAlertMessage(intl.formatMessage({ id: "error.valid", defaultMessage: "Please enter valid {field}." }, {
        field: "Max Employees Per Company"
      }));
      return false;
    }

    if ((source === "company_employees") && (maxEmployeesPerCompany > maxAllowedEmployeesPerCompany)) {
      showAlertMessage(intl.formatMessage({ id: "error.less_than_value", defaultMessage: "{field} should be less than {value}." }, {
        field: "Max Employees Per Company",
        value: maxAllowedEmployeesPerCompany
      }));
      return false;
    }

    const tmpReceiptList = (receiptList !== "") ? receiptList.split(",").every((e) => isValidEmail(e.trim())) : isRecipientOptional;

    if (!tmpReceiptList) {
      showAlertMessage(intl.formatMessage({ id: "error.valid", defaultMessage: "Please enter valid {field}." }, {
        field: intl.formatMessage({ id: "recipient", defaultMessage: "Recipient" })
      }));
      return false;
    }

    if (
      (source === "contact_posts") &&
      (_get(contactActivities, "include_posts", false) === false) &&
      (_get(contactActivities, "include_post_comments", false) === false) &&
      (_get(contactActivities, "include_post_likes", false) === false)
    ) {
      showAlertMessage(intl.formatMessage({ id: "enrich.contact.error_select_field", defaultMessage: "Please select at least one field from the list." }));
      return false;
    }

    if (
      (source === "contact_posts") &&
      ((_get(contactActivities, "include_posts", false) === true) && _get(contactActivities, "posts_limit", 0) < 1)
    ) {
      showAlertMessage(intl.formatMessage({ id: "error.enter", defaultMessage: "Please enter {field}." }, { field: intl.formatMessage({ id: "enrich_contact_activities.post_page_count", defaultMessage: "page count for posts." }) }));
      return false;
    }

    if (
      (source === "contact_posts") &&
      ((_get(contactActivities, "include_post_comments", false) === true) && _get(contactActivities, "post_comments_limit", 0) < 1)
    ) {
      showAlertMessage(intl.formatMessage({ id: "error.enter", defaultMessage: "Please enter {field}." }, { field: intl.formatMessage({ id: "enrich_contact_activities.comments_page_count", defaultMessage: "page count for comments." }) }));
      return false;
    }

    if (
      (source === "contact_posts") &&
      ((_get(contactActivities, "include_post_likes", false) === true) && _get(contactActivities, "post_likes_limit", 0) < 1)
    ) {
      showAlertMessage(intl.formatMessage({ id: "error.enter", defaultMessage: "Please enter {field}." }, { field: intl.formatMessage({ id: "enrich_contact_activities.likes_page_count", defaultMessage: "page count for likes." }) }));
      return false;
    }

    let fileResult = _get(mappingData, "uploaded_file_result[0]", {});
    let uploadedFileRes = [];

    uploadedFileRes.push({ ...fileResult, "filename": fileName });

    let payload = {
      search_name: fileName,
      uploaded_file_result: uploadedFileRes,
      fuzzy_matched: fuzzyMatched,
      data_sources: selectedSourcefield,
      is_find_result_anyway: findResultAnyway,
      include_validity_check: (includeValidityCheck || false),
      include_company_matching_score: (includeCompanyMatchingScore || false),
      include_jobtitle_score: (source === "contact") ? (includeJobtitleScore || false) : false,
      cc_emails: (isRecipientOptional === true) ? [] : ((receiptList !== "") ? receiptList.split(",") : []),
      send_email_only_me: isRecipientOptional,
      add_logged_in_user_email: (addLoggedUserInEmail || false),
      max_employees_per_company: (source === "company_employees") ? maxEmployeesPerCompany : null,
      contact_posts_data_fetch_limitation: (source === "contact_posts") ? contactActivities : {}
    }

    if (typeof saveUploadMappingData === "function") { saveUploadMappingData(payload); }
  }

  _handleCloseErrorDataModal = () => {
    this.setState({ errorMessage: "", thirdPartyErrors: [], otherErrors: [] });
  }

  _renderFailedDataModal = () => {
    const { intl } = this.props;
    const { thirdPartyErrors, otherErrors, errorMessage } = this.state;

    if (_isEmpty(thirdPartyErrors) && _isEmpty(otherErrors) && _isEmpty(errorMessage)) {
      return null;
    }

    const onlyErrorMessage = (_isEmpty(thirdPartyErrors) && _isEmpty(otherErrors));

    return (
      <Modal
        size={(onlyErrorMessage === true) ? "md" : "lg"}
        modalTitle={intl.formatMessage({ id: "enrich_contact.invalid_action", defaultMessage: "Invalid Action" })}
        onHide={() => this._handleCloseErrorDataModal()}
        onClose={() => this._handleCloseErrorDataModal()}
        isOpen={true}
        centered={true}
        showCloseButton={true}
        bodyClassName="max-h-300 overflow-auto"
        footerClassName=""
        closeBtnText={intl.formatMessage({ id: "btn.close", defaultMessage: "close" })}
      >
        <div className="errorDataModal">
          {(!_isEmpty(errorMessage)) && (
            <p className="font-weight-bold">{(errorMessage || "")}</p>
          )}

          {(onlyErrorMessage === false) && (
            <>
              {(thirdPartyErrors || []).map((object, index) => {
                const email = _get(object, "data.email", "");
                const reasons = _get(object, "reasons", {});

                return (
                  <div key={index} className="row no-gutters border p-2 mb-1 rounded">
                    <div className="col-lg-12 pb-2 text-capitalize font-weight-bold">
                      {!_isEmpty(email) ?
                        (<FormattedMessage id="file_upload.email" defaultMessage="Email:" />) :
                        (<FormattedMessage id="file_upload.row" defaultMessage="Row:" />)
                      }
                    </div>
                    <div className="col-lg-12 pb-2">
                      {(email || (index + 1))}
                    </div>
                    <div className="col-lg-12 pb-2 text-capitalize font-weight-bold">
                      <FormattedMessage id="file_upload.reasons" defaultMessage="Reasons:" />
                    </div>
                    {_map((reasons || {}), (reason, key) => (
                      <div key={key} className="col-lg-12 pb-2">
                        <FormattedMessage id="file_upload.reason_item" defaultMessage="{key} : {reason}" values={{ key, reason }} />
                      </div>
                    ))}
                  </div>
                );
              })}

              {(otherErrors || []).map((object, index) => (
                <div key={index} className="row no-gutters border p-2 mb-1 rounded">
                  <div className="col-sm-auto text-capitalize font-weight-bold">
                    {_get(object, "column", "")}
                  </div>
                  <div className="pl-1 col pb-2">
                    {_get(object, "values", "")}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </Modal>
    );
  }

  _handleNext = (requestedStep = 2) => {
    const { intl } = this.props;
    const { stepNo, mappingData, fileName } = this.state;

    if (_isEmpty(mappingData)) {
      showAlertMessage(intl.formatMessage({ id: "error.please_upload_file", defaultMessage: "Please upload a file." }));
      return false;
    }

    if ((requestedStep = 3) && _isEmpty(fileName)) {
      showAlertMessage(intl.formatMessage({ id: "error.enter", defaultMessage: "Please enter {field}." }, {
        field: intl.formatMessage({ id: "dropzone.file_name", defaultMessage: "file name" })
      }));

      return false;
    }

    this.setState({ stepNo: stepNo + 1 });
  }

  _handleRemoveFile = () => {
    const { removeUploadedFile } = this.props;
    const { fileUrl } = this.state;

    if (!fileUrl) { return false; }

    if (typeof removeUploadedFile === "function") { removeUploadedFile({ url: fileUrl }); }
  }

  _handleSourceChange = (checked, value) => {
    const { source } = this.props;
    const { selectedSourcefield, dataSource, allSelectedSourcefield } = this.state;

    let tmpSelectedSourcefield = _cloneDeep(selectedSourcefield || []);
    let tmpAllSelectedSourcefield = _cloneDeep(allSelectedSourcefield || []);
    let prioritySequence = 0;

    if (checked) {
      let tmpAllSubFields = [];

      dataSource.map((relatedSource) => {

        if (relatedSource.id === value) {
          if (!tmpAllSelectedSourcefield.includes(value)) {
            tmpAllSelectedSourcefield.push(value);
          }

          let tmpFieldGroup = relatedSource.data_field_group;

          tmpFieldGroup.map((singleGroup) => {

            let tmpFields = (singleGroup.data_fields || []);
            let tmpgroupName = (singleGroup.group_name || "contact").toLowerCase();
            let pushData = false;

            if (["contact", "company_employees"].includes(source || "contact")) {
              pushData = true;
            }

            if (((source || "contact") === "company") && ((tmpgroupName === "company") || (tmpgroupName === "common"))) {
              pushData = true;
            }

            if ((pushData || false) === true) {
              tmpFields.map((singleField) => {

                let fieldValue = 0;
                let fieldType = 0;
                if (source === "contact") {
                  (singleField.field_types || []).forEach(element => {

                    if ((tmpgroupName === "common") && ((element.field_type_id || 0) === 1)) { // if field type 1 == contact
                      fieldValue = (element.data_field_id || 0);
                      fieldType = (element.field_type_id || 0);
                    } else if (tmpgroupName !== "common") {
                      fieldValue = (element.data_field_id || 0);
                      fieldType = (element.field_type_id || 0);
                    }
                  });
                }

                if (source === "company") {
                  (singleField.field_types || []).forEach(element => {

                    if ((tmpgroupName === "common") && ((element.field_type_id || 0) === 2)) { // if field type 2 == Company
                      fieldValue = (element.data_field_id || 0);
                      fieldType = (element.field_type_id || 0);
                    } else if (tmpgroupName === "company") {
                      fieldValue = (element.data_field_id || 0);
                      fieldType = (element.field_type_id || 0);
                    }
                  });
                }

                if (source === "company_employees") {
                  (singleField.field_types || []).forEach(element => {

                    if ((tmpgroupName === "common") && ((element.field_type_id || 0) === 3)) { // if field type 3 == Company Employees
                      fieldValue = (element.data_field_id || 0);
                      fieldType = (element.field_type_id || 0);
                    } else if (tmpgroupName === "company_employees") {
                      fieldValue = (element.data_field_id || 0);
                      fieldType = (element.field_type_id || 0);
                    }
                  });
                }

                tmpAllSubFields.push({
                  "data_field_id": (fieldValue || null),
                  "data_fields": (fieldType || 0),
                });
                return false;
              });
            }

            return false;
          });
        }
        return false;
      });

      tmpSelectedSourcefield.push({
        "data_source_id": value,
        "priority_sequence": prioritySequence,
        "data_fields": tmpAllSubFields
      });
    } else {

      tmpSelectedSourcefield = [];
      tmpAllSelectedSourcefield = [];
    }

    this.setState({ selectedSourcefield: tmpSelectedSourcefield, allSelectedSourcefield: tmpAllSelectedSourcefield, isSelectedAll: checked });
  }

  _handleChange = (checked, dataSourceId, dataFieldId, selectedField, groupName) => {
    const { source } = this.props;
    const { selectedSourcefield, dataSource, allSelectedSourcefield } = this.state;

    let tmpSelectedSourcefield = _cloneDeep(selectedSourcefield || []);
    let tmpAllSelectedSourcefield = _cloneDeep(allSelectedSourcefield || []);
    let tmpDataSource = _cloneDeep(dataSource || []);
    let isSelectedAll = false;

    if (checked) {
      let prioritySequence = tmpAllSelectedSourcefield.length + 1;
      // If First Time Select field of related source,  Add Source Array and it's selected field
      if (!tmpAllSelectedSourcefield.includes(dataSourceId)) {
        tmpAllSelectedSourcefield.push(dataSourceId);

        let fieldValue = 0;
        let fieldType = 0;

        if (source === "contact") {
          (selectedField.field_types || []).forEach(element => {

            if (((groupName || "contact") === "common") && ((element.field_type_id || 0) === 1)) { // if field type 1 == contact
              fieldValue = (element.data_field_id || 0);
              fieldType = (element.field_type_id || 0);
            } else if ((groupName || "contact") !== "common") {
              fieldValue = (element.data_field_id || 0);
              fieldType = (element.field_type_id || 0);
            }
          });
        }

        if (source === "company") {
          (selectedField.field_types || []).forEach(element => {
            if (((groupName || "contact") === "common") && ((element.field_type_id || 0) === 2)) { // if field type 2 == Company
              fieldValue = (element.data_field_id || 0);
              fieldType = (element.field_type_id || 0);
            } else if ((groupName || "contact") === "company") {
              fieldValue = (element.data_field_id || 0);
              fieldType = (element.field_type_id || 0);
            }
          });
        }

        if (source === "company_employees") {
          (selectedField.field_types || []).forEach(element => {
            if (((groupName || "contact") === "common") && ((element.field_type_id || 0) === 3)) { // if field type 3 == Company Employees
              fieldValue = (element.data_field_id || 0);
              fieldType = (element.field_type_id || 0);
            } else if ((groupName || "contact") === "company_employees") {
              fieldValue = (element.data_field_id || 0);
              fieldType = (element.field_type_id || 0);
            }
          });
        }

        tmpSelectedSourcefield.push({
          "data_source_id": dataSourceId,
          "priority_sequence": prioritySequence,
          "data_fields": [{
            "data_field_id": (fieldValue || null),
            "data_fields": (fieldType || 0),
          }]
        });
      } else {  // If related data source already added then add datafield to its array
        tmpDataSource.map((selectedSource) => {

          if (selectedSource.id === dataSourceId) {

            tmpSelectedSourcefield.map((relatedSource) => {

              if ((relatedSource.data_source_id || 0) === dataSourceId) {

                let fieldValue = 0;
                let fieldType = 0;
                if (source === "contact") {
                  (selectedField.field_types || []).forEach(element => {

                    if (((groupName || "contact") === "common") && ((element.field_type_id || 0) === 1)) { // if field type 1 == contact
                      fieldValue = (element.data_field_id || 0);
                      fieldType = (element.field_type_id || 0);
                    } else if ((groupName || "contact") !== "common") {
                      fieldValue = (element.data_field_id || 0);
                      fieldType = (element.field_type_id || 0);
                    }
                  });
                }

                if (source === "company") {
                  (selectedField.field_types || []).forEach(element => {
                    if (((groupName || "contact") === "common") && ((element.field_type_id || 0) === 2)) { // if field type 2 == Company
                      fieldValue = (element.data_field_id || 0);
                      fieldType = (element.field_type_id || 0);
                    } else if ((groupName || "contact") === "company") {
                      fieldValue = (element.data_field_id || 0);
                      fieldType = (element.field_type_id || 0);
                    }
                  });
                }

                if (source === "company_employees") {
                  (selectedField.field_types || []).forEach(element => {
                    if (((groupName || "contact") === "common") && ((element.field_type_id || 0) === 3)) { // if field type 3 == Company Employees
                      fieldValue = (element.data_field_id || 0);
                      fieldType = (element.field_type_id || 0);
                    } else if ((groupName || "contact") === "company_employees") {
                      fieldValue = (element.data_field_id || 0);
                      fieldType = (element.field_type_id || 0);
                    }
                  });
                }

                relatedSource.data_fields.push({
                  "data_field_id": (fieldValue || null),
                  "data_fields": (fieldType || 0),
                });
              }
              return false;
            });
          }
          return false;
        });
      }

      let sourceLength = 0;
      _forEach(_get(dataSource, "[0].data_field_group", []), (g) => {
        const gName = (g.group_name || "").toLowerCase();

        if (["contact", "company_employees"].includes(source)) {
          sourceLength += (g.data_fields || []).length;
        }

        if ((source === "company") && [source, "common"].includes(gName)) {
          sourceLength += (g.data_fields || []).length;
        }
      });

      isSelectedAll = (_get(tmpSelectedSourcefield, "[0].data_fields", []).length === sourceLength);
    } else {

      //if uncheck remove deselected field from data resource state for post submission
      tmpSelectedSourcefield.map((relatedSource, k) => {

        if ((relatedSource.data_source_id || 0) === dataSourceId) {

          let selectedDataFields = relatedSource.data_fields;
          selectedDataFields.map((selectedField, key) => {

            if (selectedField.data_field_id === dataFieldId) {
              selectedDataFields.splice(key, 1);
            }
            return false;
          });

          if (selectedDataFields.length === 0) {

            tmpSelectedSourcefield.splice(k, 1);

            const index = tmpAllSelectedSourcefield.indexOf(dataSourceId);
            if (index > -1) {
              tmpAllSelectedSourcefield.splice(index, 1);
            }
          }
        }
        return false;
      });
    }

    this.setState({ selectedSourcefield: tmpSelectedSourcefield, allSelectedSourcefield: tmpAllSelectedSourcefield, isSelectedAll: isSelectedAll });
  }

  handleKeyDown = (e) => {
    // Prevent input of 'e' or 'E'
    if (e.key === "e" || e.key === "E") {
      e.preventDefault();
    }
  }

  calculateEstimation = () => {
    const { contactActivities: { posts_limit, post_comments_limit, post_likes_limit, include_post_comments, include_post_likes }, mappingData } = this.state;
    let contactProfile = _get(mappingData, "total_records", 0);
    let creditsPosts = 0;
    let creditsComments = 0;
    let creditsLikes = 0;
    let totalCredits = 0;

    // Calculate credits for posts
    creditsPosts = contactProfile * posts_limit;

    // Calculate credits for comments if post_comments_limit is greater than 0
    if (post_comments_limit > 0 && include_post_comments) {
      creditsComments = creditsPosts * post_comments_limit * 10;
    }

    // Calculate credits for likes if pagesLikes is greater than 0
    if (post_likes_limit > 0 && include_post_likes) {
      creditsLikes = creditsPosts * post_likes_limit * 10;
    }

    // Calculate total credits
    totalCredits = contactProfile + creditsPosts + creditsComments + creditsLikes;

    this.setState((prevState) => ({
      contactActivities: {
        ...prevState.contactActivities,
        totalEstimatedCredits: (totalCredits || 0)
      },
    }));
  }

  _renderStep1 = () => {
    const { acceptedFormat = "image/*", allowedExtensions, displayUploadedFiles = false, source } = this.props;
    const { selectedFile = {}, isDragOver = false, progress, /* jobId */ } = this.state;

    const maxFileSize = (Number(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE || 10000000) / 1000000);

    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="mt-9">
              {/* <div>
              <h6 className="heading-05 mb-16">
                <FormattedMessage id="enrich_contact.job_title" defaultMessage="Select which jobs you'd like to run" />
              </h6>

              {_map((jobTypes), (j, i) => (
                <div className="form-check form-check-inline me-lg-60" key={i}>
                  <input
                    className="form-check-input"
                    name="jobTypes"
                    type="radio"
                    value={(j.id || null)}
                    id={`jobTypes-${(j.id || null)}`}
                    checked={(jobId === (j.id || null))}
                    onChange={(e) => this.setState({ jobId: (j.id || null) })}
                  />
                  <label className="form-check-label" htmlFor={`jobTypes-${(j.id || null)}`}>{(j.name || "")}</label>
                </div>
              ))}
            </div> */}

              <div>
                {(displayUploadedFiles === true && _isEmpty(selectedFile || {})) && (
                  <Dropzone
                    maxFiles={1}
                    multiple={false}
                    accept={acceptedFormat}
                    onDrop={(f, fileRejections) => this._onDrop(f, fileRejections)}
                    onDragOver={() => this.setState({ isDragOver: true })}
                    onDragLeave={() => this.setState({ isDragOver: false })}
                    maxSize={Number(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE || 10000000)}
                    ref={dropzoneRef}
                  >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                      <div {...getRootProps({ className: `file-upload ${(isDragOver === true) ? "drop-container" : ""}` })}>
                        <div className="file-upload-box">
                          <IconUpload width="45px" height="40px" color="#0010f7" />
                          <input {...getInputProps()} />
                          <p className="text-dark mb-2 mt-6">
                            <FormattedMessage id="dropzone.title" defaultMessage="Drag & Drop Here" />
                          </p>
                          <p className="text-grey-63 mb-2">
                            <FormattedMessage
                              id="dropzone.allowed_files"
                              defaultMessage="Accepted file types : {allowedExtensions}"
                              values={{ allowedExtensions: allowedExtensions }}
                            />
                          </p>
                          <p className="text-grey-63">
                            <FormattedMessage
                              id="dropzone.max_file_size"
                              defaultMessage="Maximum file size {maxFilesize} MB or maximum number of rows up to 25K."
                              values={{ maxFilesize: maxFileSize }}
                            />
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                )}

                {(displayUploadedFiles === true && !_isEmpty(selectedFile || {})) && (
                  <Fragment>
                    {(progress < 100) && (
                      <div className="file-upload-progress position-relative d-flex align-items-center mt-40">
                        <div className="circle-progress">
                          <svg height="106" width="106">
                            <circle cx="53" cy="53" r="48"></circle>
                            <circle cx="53" cy="53" r="48" style={{ "--percent": progress }} pathLength="100"></circle>
                          </svg>

                          <div className="circle-progress-number">
                            <p>{progress}%</p>
                          </div>
                        </div>
                      </div>
                    )}

                    {(progress >= 100) && (
                      <div className="file-upload-list">
                        <div className="file-upload-list-item">
                          <IconAttach width="13px" height="14px" color="none" />
                          <span>{(selectedFile.name || "file-name.xls")}</span>
                          <span className="icon-remove-file" onClick={() => this._handleRemoveFile()}><IconDelete width="14px" height="14px" color="none" /></span>
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}

                {(source === "contact") && (
                  <div>
                    {/* <h6 className="text-dark heading-05 fw-medium mt-30 mb-10">
                    <FormattedMessage id="enrich_contact.instruction.title" defaultMessage="Import Instruction: Contact Search" />
                  </h6>

                  <div className="col-lg-12 col-lg-12 important-instruction">
                    <p>
                      <span className="heading-07 fst-italic text-capitalize-first">
                        <FormattedMessage id="enrich_contact.instruction.one" defaultMessage="If you are searching contact using LinkedIn URL, please make sure the column is available and values are well populated in your file." />
                      </span>
                    </p>
                    <p>
                      <span className="heading-07 fst-italic text-capitalize-first">
                        <FormattedMessage id="enrich_contact.instruction.two" defaultMessage="If the search is based on other criteria, the file should have all these fields and values available: First Name, Last Name, and Company for the best search result." />
                      </span>
                    </p>
                    <p>
                      <span className="heading-07 fst-italic text-capitalize-first">
                        <FormattedMessage id="enrich_contact.instruction.three" defaultMessage="Any of your column header names in the file must not have the prefix 'Enrich_' as this is a reserved prefix for result columns." />
                      </span>
                    </p>
                  </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div >

        <div className="row">
          <div className="col-lg-12">
            <div className="text-end mt-12">
              <button type="button" className="btn btn-outline-primary text-capitalize" onClick={() => this._handleNext(2)}>
                <FormattedMessage id="btn.next" defaultMessage="next" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderStep2 = () => {
    const { intl } = this.props;
    const { stepNo, mappingData, fileName } = this.state;

    const headerList = _cloneDeep(mappingData.header || []);
    const dropdownValues = _cloneDeep(mappingData.dropdownValues || []);
    let fuzzyMatched = _cloneDeep(mappingData.fuzzyMatched || []);

    return (
      <div className="row">
        <div className="col-xl-4 col-lg-5">
          <div className="my-4 d-flex align-items-center gap-2">
            <label className="form-label text-nowrap"><FormattedMessage id="enrich_contact.file_name" defaultMessage="File Name:" /></label>
            <input type="text" className="form-control form-control-sm" placeholder="File Name" value={(fileName || "")} onChange={(e) => this.setState({ fileName: _get(e, "target.value", "") })} />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="table-responsive max-h-500 vh-100">
            <table className="table table-bordered">
              <thead>
                <tr>
                  {(fuzzyMatched || []).map((f, j) => {
                    return (
                      <th key={`map-dropdown-${j}`}>
                        {_get(headerList, `[${j}]`, "")}

                        <Select
                          className="form-select-custom form-select-custom-sm form-select-custom-170 mt-4 mb-2"
                          placeholder={intl.formatMessage({ id: "placeholder.select_field", defaultMessage: "Select {field}" },
                            { field: intl.formatMessage({ id: "fields", defaultMessage: "fields" }) })}
                          value={(f.matched_dropdown_Value || null)}
                          options={dropdownValues}
                          isMulti={false}
                          getOptionValue={(option) => (option.key)}
                          getOptionLabel={(option) => (option.value)}
                          onChange={(e) => {
                            let tmp = fuzzyMatched;
                            tmp[j].matched_dropdown_Value = e;

                            this.setState({ mappingData: { ...mappingData, fuzzyMatched: tmp } });
                          }}
                        />
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>

                {(mappingData.sampleData || []).map((v, l) => {
                  return (
                    <tr key={`map-data-${l}`}>
                      {Object.values(v || []).map((w, k) => (<td key={`map-content-${k}`}>{(w || "")}</td>))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="btns position-sticky bottom-0 bg-white pt-2 pb-2">
            <div className="row">
              <div className="col-md-6">
                <button type="button" className="btn btn-outline-primary text-capitalize" onClick={() => this.setState({ stepNo: stepNo - 1 })}>
                  <FormattedMessage id="btn.back" defaultMessage="back" />
                </button>
              </div>

              <div className="col-md-6 text-end">
                <button type="button" className="btn btn-outline-primary text-capitalize" onClick={() => this._handleNext(3)}>
                  <FormattedMessage id="btn.next" defaultMessage="next" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderStep3 = () => {
    const { source } = this.props;
    const { stepNo, selectedSourcefield, dataSource, isSelectedAll/*, findResultAnyway*/ } = this.state;

    const tmpDataSourceGroup = _get(dataSource, "[0].data_field_group", []);
    const sourceId = _get(dataSource, "[0].id", null);
    const existKey = this._checkInArray(sourceId, selectedSourcefield);

    return (
      <div className="row">
        <div className="col-lg-12">
          <h4 className="heading-04 fw-medium mt-8">
            <FormattedMessage id="enrich_contact.step_title" defaultMessage="Select the data that you want to add" />
          </h4>

          {(source !== "contact_posts") && (
            <div className="row gy-5 mt-3 mb-10">
              <div className="col-lg-12">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id={`data_source-${sourceId}`}
                    className="form-check-input"
                    value={(sourceId || null)}
                    checked={(isSelectedAll || false)}
                    onChange={(e) => this._handleSourceChange(_get(e, "currentTarget.checked", false), sourceId)}
                  />
                  <label className="form-check-label heading-07 text-capitalize" htmlFor={`data_source-${sourceId}`}>
                    <FormattedMessage id="enrich_contact.select_all" defaultMessage="Select All" />
                  </label>
                </div>
              </div>

              {!_isEmpty(tmpDataSourceGroup) && (
                Object.keys((tmpDataSourceGroup || [])).map((values, vk) => {

                  const tmpSelectedIds = _map(_get(selectedSourcefield, `[${existKey}].data_fields`, []), "data_field_id");

                  const key = vk;
                  const groupName = _get(tmpDataSourceGroup, `[${vk}].group_name`, "contact").toLowerCase();
                  const dataFields = _get(tmpDataSourceGroup, `[${vk}].data_fields`, []);
                  const mandatoryColumns = _get(tmpDataSourceGroup, `[${vk}].mandatory_columns`, []);

                  return (
                    <Fragment key={`data-fields-${key}-${vk}`}>
                      {!_isEmpty(tmpDataSourceGroup[vk]) && (
                        Object.values((dataFields || [])).map((vf, k) => {
                          let fieldValue = 0;
                          let tooltip = "";

                          if (source === "contact") {
                            (vf.field_types || []).forEach(element => {
                              tooltip = (element.tooltip || "");

                              if (((groupName || "contact") === "common") && ((element.field_type_id || 0) === 1)) { // if field type 1 == contact
                                fieldValue = (element.data_field_id || 0);
                              } else if ((groupName || "contact") !== "common") {
                                fieldValue = (element.data_field_id || 0);
                              }
                            });
                          }

                          if (source === "company") {
                            (vf.field_types || []).forEach(element => {
                              tooltip = (element.tooltip || "");

                              if ((source === "company") && ((element.field_type_id || 0) === 2)) { // if field type 2 == Company
                                fieldValue = (element.data_field_id || 0);
                              }
                            });
                          }

                          if (source === "company_employees") {
                            (vf.field_types || []).forEach(element => {
                              tooltip = (element.tooltip || "");

                              if ((source === "company_employees") && ((element.field_type_id || 0) === 3)) { // if field type 3 == Company Employees
                                fieldValue = (element.data_field_id || 0);
                              }
                            });
                          }

                          return (
                            <div key={`field-${k}`} className="col-lg-3">

                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id={`contact_field-${key}-${fieldValue}`}
                                  className="form-check-input"
                                  name="contact_field[]"
                                  value={(fieldValue || 0)}
                                  checked={((tmpSelectedIds).includes(fieldValue || null)) ? true : false}
                                  onChange={(e) => this._handleChange(e.currentTarget.checked, sourceId, fieldValue, vf, groupName)}
                                />
                                <label className="form-check-label heading-07 text-capitalize" htmlFor={`contact_field-${key}-${fieldValue}`}>
                                  {(vf.field_name || "")}
                                  {!_isEmpty(tooltip) && (
                                    <OverlayTrigger
                                      overlay={(<Tooltip className="title title--smx tooltip-cls">{(tooltip || "")}</Tooltip>)}
                                      placement="top"
                                    >
                                      <i className="fa fa-info-circle ml-1" />
                                    </OverlayTrigger>
                                  )}
                                </label>
                              </div>
                            </div>
                          );
                        })
                      )}

                      {!_isEmpty(tmpDataSourceGroup[vk]) && (
                        Object.values((mandatoryColumns || [])).map((vf, k) => {
                          return (
                            <div key={`field-${k}`} className="col-lg-3">

                              <div className="form-check mb-10">
                                <input
                                  type="checkbox"
                                  id={`contact_field-${key}-${vf.id}`}
                                  className="form-check-input"
                                  checked={((tmpSelectedIds).includes(vf.id || null)) ? true : false}
                                  onChange={(e) => this._handleChange(e.currentTarget.checked, sourceId, vf.id, vf, groupName)}
                                  disabled={(source !== groupName) ? true : false}
                                />
                                <label className="form-check-label heading-07 text-capitalize" htmlFor={`contact_field-${key}-${vf.id}`}>{(vf.field_name || "")}
                                  {!_isEmpty(vf.tooltip) && (
                                    <OverlayTrigger
                                      overlay={(<Tooltip className="title title--smx tooltip-cls">{vf.tooltip}</Tooltip>)}
                                      placement="top"
                                    >
                                      <i className="fa fa-info-circle ml-1" />
                                    </OverlayTrigger>
                                  )}
                                </label>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </Fragment>
                  );
                })
              )}
            </div>
          )}

          {/*(source !== "company_employees") && (
            <div className="row mt-20">
              <div className="col-lg-12 mb-10">
                <h5 className="heading-06 fw-medium">
                  <FormattedMessage id="enrich_contact.find_result_anyway" defaultMessage="Extended Search" />
                </h5>
                <div className="col-lg-12 important-instruction mb-5">
                  <p className="heading-07 fst-italic text-capitalize-first mt-2">
                    <span>
                      <FormattedMessage id="enrich_contact.find_result_anyway.note" defaultMessage="This option will consume extra credits but will try to find results by using various combinations of the values available in your file." />
                    </span>
                  </p>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="find-result-anyway"
                    className="form-check-input"
                    checked={(findResultAnyway || false)}
                    onChange={(e) => this.setState({ findResultAnyway: !findResultAnyway })}
                  />
                  <label className="form-check-label heading-07 text-capitalize" htmlFor="find-result-anyway">
                    <FormattedMessage id="enrich_contact.include_extendedsearch" defaultMessage="Include Extended Search" />
                  </label>
                </div>
              </div>
            </div>
          )*/}

          {/* {this._renderMatchingPer()} */}
          {(source === "company_employees" && source !== "contact_posts") && this._renderMaxEmployeesPerCompany()}
          {(source !== "company_employees" && source !== "contact_posts") && this._renderIncludeFields()}
          {(source === "contact_posts") && this._renderContactActivities()}
          {this._renderCreditSection()}
        </div>

        <div className="btns position-sticky bg-white bottom-0 bg-white pt-2 pb-2 px-2">
          <div className="row">
            <div className="col-md-6">
              <button type="button" className="btn btn-outline-primary text-capitalize" onClick={() => this.setState({ stepNo: stepNo - 1 })}>
                <FormattedMessage id="btn.back" defaultMessage="back" />
              </button>
            </div>

            <div className="col-md-6 text-end">
              <button type="button" className="btn btn-primary text-capitalize" onClick={() => this.setState({ showReceiptModal: true })}>
                <FormattedMessage id="btn.submit" defaultMessage="submit" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderContactActivities = () => {
    const { contactActivities } = this.state;

    return (
      <div className="row">
        <div className="col-lg-6">
          <div className="row gy-5 mt-3 mb-2">
            <div className="col-lg-2">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="include_posts"
                  className="form-check-input"
                  checked={_get(contactActivities, "include_posts", false)}
                  disabled={true}
                  onChange={(e) => {
                    const includePostsChecked = _get(e, "currentTarget.checked", false);

                    this.setState((prevState) => ({
                      contactActivities: {
                        ...prevState.contactActivities,
                        include_posts: !_get(contactActivities, "include_posts", false),
                        posts_limit: (includePostsChecked === false) ? 0 : 1
                      },
                    }))
                  }}
                />
                <label className="form-check-label heading-07 text-capitalize" htmlFor="include_posts">
                  <FormattedMessage id="enrich_contact_activities.posts" defaultMessage="posts" />
                </label>
              </div>
            </div>
            <div className="col-lg-2">
              <input
                type="number"
                className="form-control form-control-sm"
                value={_get(contactActivities, "posts_limit", 0)}
                disabled={!_get(contactActivities, "include_posts", false)}
                onKeyDown={(e) => this.handleKeyDown(e)}
                onChange={(e) => {
                  const value = _get(e, "target.value", 0);
                  if (parseInt(value) < 1) {
                    return false;
                  }

                  this.setState((prevState) => ({
                    contactActivities: {
                      ...prevState.contactActivities,
                      posts_limit: value,
                    },
                  }))
                }}
              />
            </div>
            <div className="col-lg-3 mt-7 px-0">
              <label className="form-label text-nowrap text-capitalize">
                <FormattedMessage id="enrich_contact_activities.pages" defaultMessage="pages" />
              </label>
            </div>
          </div>

          <div className="row gy-5 mt-3 mb-2">
            <div className="col-lg-2 mt-0">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="include_post_comments"
                  className="form-check-input"
                  checked={_get(contactActivities, "include_post_comments", false)}
                  onChange={(e) => {
                    const includePostsChecked = _get(e, "currentTarget.checked", false);

                    this.setState((prevState) => ({
                      contactActivities: {
                        ...prevState.contactActivities,
                        include_post_comments: !_get(contactActivities, "include_post_comments", false),
                        post_comments_limit: (includePostsChecked === false) ? 0 : 1
                      },
                    }))
                  }}
                />
                <label className="form-check-label heading-07 text-capitalize" htmlFor="include_post_comments">
                  <FormattedMessage id="enrich_contact_activities.comments" defaultMessage="comments" />
                </label>
              </div>
            </div>
            <div className="col-lg-2 mt-0">
              <input
                type="number"
                className="form-control form-control-sm"
                value={_get(contactActivities, "post_comments_limit", 0)}
                disabled={!_get(contactActivities, "include_post_comments", false)}
                onKeyDown={(e) => this.handleKeyDown(e)}
                onChange={(e) => {
                  const value = _get(e, "target.value", 0);
                  if (parseInt(value) < 1) {
                    return false;
                  }

                  this.setState((prevState) => ({
                    contactActivities: {
                      ...prevState.contactActivities,
                      post_comments_limit: value,
                    },
                  }))
                }}
              />
            </div>
            <div className="col-lg-3 mt-2 px-0">
              <label className="form-label text-nowrap text-capitalize">
                <FormattedMessage id="enrich_contact_activities.pages" defaultMessage="pages" />
              </label>
            </div>
          </div>
          <div className="row gy-5 mt-3 mb-2">
            <div className="col-lg-2 mt-0">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="include_post_likes"
                  className="form-check-input"
                  checked={_get(contactActivities, "include_post_likes", false)}
                  onChange={(e) => {
                    const includePostsChecked = _get(e, "currentTarget.checked", false);

                    this.setState((prevState) => ({
                      contactActivities: {
                        ...prevState.contactActivities,
                        include_post_likes: !_get(contactActivities, "include_post_likes", false),
                        post_likes_limit: (includePostsChecked === false) ? 0 : 1
                      },
                    }))
                  }}
                />
                <label className="form-check-label heading-07 text-capitalize" htmlFor="include_post_likes">
                  <FormattedMessage id="enrich_contact_activities.likes" defaultMessage="likes" />
                </label>
              </div>
            </div>
            <div className="col-lg-2 mt-0">
              <input
                type="number"
                className="form-control form-control-sm"
                value={_get(contactActivities, "post_likes_limit", 0)}
                disabled={!_get(contactActivities, "include_post_likes", false)}
                onKeyDown={(e) => this.handleKeyDown(e)}
                onChange={(e) => {
                  const value = _get(e, "target.value", 0);
                  if (parseInt(value) < 1) {
                    return false;
                  }

                  this.setState((prevState) => ({
                    contactActivities: {
                      ...prevState.contactActivities,
                      post_likes_limit: value,
                    },
                  }))
                }}
              />
            </div>
            <div className="col-lg-3 mt-2 px-0">
              <label className="form-label text-nowrap text-capitalize">
                <FormattedMessage id="enrich_contact_activities.pages" defaultMessage="pages" />
              </label>
            </div>
          </div>
          <div className="row gy-5 mt-3 mb-5">
            <label className="form-label text-nowrap">
              <b><FormattedMessage id="enrich_contact_activities.note" defaultMessage="*Note: Ideally one page contains 10 items" /></b>
            </label>
          </div>
        </div>
        <div className="col-lg-6 text-center">
          <h4 className="heading-04 fw-medium mt-6 mb-6">
            <FormattedMessage id="enrich_contact.calculate_heading" defaultMessage="Calculate the estimated total credit for this search" />
          </h4>
          <button type="button" className="btn btn-outline-primary text-capitalize mb-5" onClick={() => this.calculateEstimation()}>
            <FormattedMessage id="btn.calculate" defaultMessage="calculate" />
          </button>
          <p className="heading-02 fw-medium font-weight-bold">{_get(contactActivities, "totalEstimatedCredits", 0)}</p>
        </div>
      </div>
    );
  }

  _renderCreditSection = () => {
    const { remainingCredits, intl } = this.props;
    const { mappingData } = this.state;

    if (_get(mappingData, "credit_details.insufficient_credits", false) === false) { return null; }

    return (
      <div className="row mt-20 mb-10">
        <div className="col-lg-12 mt-30">
          <h4 className="heading-03 fw-medium mb-7 text-capitalize">
            <FormattedMessage id="enrich_contact.cost" defaultMessage="Cost" />
          </h4>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6">
                <div className="d-flex align-items-center gap-4 mb-8">
                  <div>
                    <img src="images/icons/icon-record.svg" className="img-fluid" alt={intl.formatMessage({ id: "enrich_contact.upload_records", defaultMessage: "Records" })} />
                  </div>
                  <div>
                    <p className="heading-05 fw-bold mb-1">{_get(mappingData, "credit_details.total_records", 0)}</p>
                    <p className="heading-07 text-grey-63"><FormattedMessage id="enrich_contact.upload_records" defaultMessage="Records" /></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex align-items-center gap-4 mb-8">
                  <div>
                    <img src="images/icons/icon-credits.svg" className="img-fluid" alt={intl.formatMessage({ id: "enrich_contact.total_credits", defaultMessage: "Credits" })} />
                  </div>
                  <div>
                    <p className="heading-05 fw-bold mb-1">{(remainingCredits || 0)}</p>
                    <p className="heading-07 text-grey-63"><FormattedMessage id="enrich_contact.total_credits" defaultMessage="Credits" /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="d-flex flex-sm-row flex-column align-items-lg-center gap-lg-11 gap-4">
            <p className="bg-danger bg-opacity-10 rounded-2 text-danger p-4 text-center">
              <FormattedMessage id="enrich_contact.insufficient_credit_message" defaultMessage="You have insufficient credits" />
            </p>
            <Link className="btn btn-primary text-capitalize" to="/add-credits" target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="enrich_contact.add_credits" defaultMessage="Add Credits" />
            </Link>
          </div>
        </div>
      </div>
    );
  }

  _renderMatchingPer = () => {
    const { matchingPer } = this.state;

    return (
      <div className="row mt-20">
        <div className="col-lg-12">
          <h4 className="heading-05 mb-20">
            <FormattedMessage id="enrich_contact.your_matching" defaultMessage="Your Matching %" />
          </h4>
          {_map((matchingPer), (m, i) => {
            const perType = _get(m, "search_matching_text", "");

            return (
              <div className="matching-level mb-16 d-flex align-items-center" key={`matching-per-${i}`}>
                <p className={(perType === "Rejected") ? "text-danger" : ((perType === "Warning") ? "text-warning" : "text-primary")}>{perType}</p>
                <p className="position-relative">
                  <input type="text" className="px-10 text-center form-control bg-white heading-06" defaultValue={_get(m, "min_percentage", "")} readOnly />
                  <span className="position-absolute top-50 end-0 translate-middle-y pe-15">%</span>
                </p>
                <p className="text-center"><FormattedMessage id="to" defaultMessage="to" /></p>
                <p className="position-relative">
                  <input type="text" className="px-10 text-center form-control bg-white heading-06" defaultValue={_get(m, "max_percentage", "")} readOnly />
                  <span className="position-absolute top-50 end-0 translate-middle-y pe-15">%</span>
                </p>
              </div>
            )
          })}
        </div>
      </div>
    );
  }

  _renderIncludeFields = () => {
    const { source } = this.props;
    const { includeValidityCheck, includeCompanyMatchingScore, includeJobtitleScore } = this.state;

    return (
      <div className="row mt-20">
        <div className="col-lg-12">
          <h5 className="heading-06 fw-medium mb-5">
            <FormattedMessage id="enrich_contact.include_fields.title" defaultMessage="Enrich Matching Validity and Score/Rank: Original vs. LI Values" />
          </h5>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-check mb-5">
                <input
                  type="checkbox"
                  id="include_validity_check"
                  className="form-check-input"
                  checked={(includeValidityCheck || false)}
                  onChange={(e) => this.setState({ includeValidityCheck: !includeValidityCheck })}
                />
                <label className="form-check-label heading-07 text-capitalize" htmlFor="include_validity_check">
                  <FormattedMessage id="enrich_contact.include_fields.include_validity_check" defaultMessage="Include Validity Check" />
                </label>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-check mb-5">
                <input
                  type="checkbox"
                  id="includeCompanyMatchingScore"
                  className="form-check-input"
                  checked={(includeCompanyMatchingScore || false)}
                  onChange={(e) => this.setState({ includeCompanyMatchingScore: !includeCompanyMatchingScore })}
                />
                <label className="form-check-label heading-07 text-capitalize" htmlFor="includeCompanyMatchingScore">
                  <FormattedMessage id="enrich_contact.include_fields.include_company_matching_score" defaultMessage="Include Company Matching Score/Rank" />
                </label>
              </div>
            </div>

            {(source === "contact") && (
              <div className="col-lg-12">
                <div className="form-check mb-7">
                  <input
                    type="checkbox"
                    id="includeJobtitleScore"
                    className="form-check-input"
                    checked={(includeJobtitleScore || false)}
                    onChange={(e) => this.setState({ includeJobtitleScore: !includeJobtitleScore })}
                  />
                  <label className="form-check-label heading-07 text-capitalize" htmlFor="includeJobtitleScore">
                    <FormattedMessage id="enrich_contact.include_fields.include_jobtitle_score" defaultMessage="Include Job Title Matching Score/Rank" />
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  _renderMaxEmployeesPerCompany = () => {
    const { maxEmployeesPerCompany } = this.state;

    return (
      <div className="row mt-20">
        <div className="col-lg-12">
          <h5 className="heading-06 fw-medium mb-10">
            <FormattedMessage id="enrich_company_employees.max_employees_per_company" defaultMessage="Max Employees Per Company" />
          </h5>
          <div className="row">
            <div className="col-lg-2 mb-15">
              <input
                type="text"
                className="form-control form-control-sm border"
                placeholder="Please enter max employees per company"
                value={(maxEmployeesPerCompany || 0)}
                onChange={(e) => {
                  const val = _get(e, "target.value", "");

                  if (/^\d*$/.test(val)) {
                    this.setState({ maxEmployeesPerCompany: parseInt(val || 0) });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderMessage = () => {
    const { successMessage } = this.state;

    return (
      <div className="row">
        <div className="col-lg-12 text-center">
          <div className="bg-white border p-6 rounded-4 mb-5 mh-600">
            <div className="text-center mt-12">
              <IconSuccess width="110px" height="110px" color="#0010F7" />
              <div className="heading-02 fw-bold mt-8 mb-3" role="alert">
                <FormattedMessage id="success" defaultMessage="Success" />
              </div>
              <p className="text-grey-63 mb-6">
                {(parse(successMessage) || <FormattedMessage id="dropzone.file_process_message" defaultMessage="Your file is being processed and will be ready soon, please check your searches to get an update" />)}
              </p>
              <div>
                <Link to="/searches" type="button" className="btn btn-primary text-capitalize">
                  <FormattedMessage id="btn.searches" defaultMessage="Searches" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderWorksheetSelection = () => {
    const { workSheets, intl } = this.props;
    const { selectedWorksheet, showWorksheetModal, errors } = this.state;

    const tmpWorkSheets = _map((workSheets || []), (w) => ({ key: w, value: w }));

    return (
      <Modal
        showCloseButton={true}
        isOpen={showWorksheetModal}
        className="addContentModal"
        modalTitle={intl.formatMessage({ id: "enrich_contact.modal_title.select_worksheet", defaultMessage: "Select Worksheet" })}
        onClose={() => this.setState({ showWorksheetModal: false })}
        onSubmit={() => this._onSubmitWorksheetName()}
        closeBtnText={intl.formatMessage({ id: "btn.close", defaultMessage: "close" })}
        submitBtnText={intl.formatMessage({ id: "btn.submit", defaultMessage: "submit" })}
      >
        <div className="h-350">
          <h5 className="mb-15">{(errors.message || "")}</h5>
          <div className="col-lg mb-15">
            <div className="position-relative mb-lg-0">
              <Select
                className="text-capitalize form-select-custom"
                key={`confirmation-${(selectedWorksheet || null)}`}
                placeholder={intl.formatMessage({ id: "enrich_contact.modal_title.select_worksheet", defaultMessage: "Select Worksheet" })}
                value={(selectedWorksheet) ? { key: (selectedWorksheet || null), value: (selectedWorksheet || null) } : null}
                options={(tmpWorkSheets || [])}
                getOptionValue={(option) => (option.key || "")}
                getOptionLabel={(option) => (option.value || "")}
                isMulti={false}
                onChange={(selectedOptions) => this.setState({ selectedWorksheet: (selectedOptions.key || "") })}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  _renderAdditionalButton = () => {
    return (
      <div>
        <button className="btn btn-md btn-dark text-capitalize" onClick={() => this._handleSubmit(true)}>
          <FormattedMessage id="btn.just_me_please" defaultMessage="just me, please" />
        </button>
      </div>
    );
  }

  _renderSendReceiptModal = () => {
    const { userEmail, intl } = this.props;
    const { showReceiptModal, receiptList, addLoggedUserInEmail } = this.state;

    return (
      <Modal
        showCloseButton={true}
        isOpen={showReceiptModal}
        className="addContentModal"
        modalTitle={intl.formatMessage({ id: "enrich_contact.receipt_modal.title", defaultMessage: "Recipient List" })}
        onClose={() => this.setState({ showReceiptModal: false })}
        onSubmit={() => this._handleSubmit(false)}
        closeBtnText={intl.formatMessage({ id: "btn.close", defaultMessage: "close" })}
        submitBtnText={intl.formatMessage({ id: "btn.submit", defaultMessage: "submit" })}
        additionalButton={this._renderAdditionalButton()}
      >
        <div>
          <div className="form-check form-check-inline mb-3" >
            <input
              type="checkbox"
              id="loggedInUserEmail"
              className="form-check-input"
              checked={(addLoggedUserInEmail || false)}
              onChange={(e) => this.setState({ addLoggedUserInEmail: !addLoggedUserInEmail })}
            />
            <label className="form-check-label heading-07" htmlFor="loggedInUserEmail">
              <FormattedMessage
                id="enrich_contact.receipt_modal.user_email_note"
                defaultMessage="Include {loggedinUserEmail}"
                values={{ loggedinUserEmail: (userEmail || "") }}
              />
            </label>
          </div>
          <div className="col-lg">
            <div className="position-relative mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Add Recipients"
                value={(receiptList || "")}
                onChange={(e) => this.setState({ receiptList: _get(e, "target.value", "") })}
              />
            </div>
          </div>
          <div className="col-lg-12 important-instruction">
            <p className="heading-07 fst-italic text-capitalize-first lh-base">
              <span>
                <FormattedMessage id="enrich_contact.receipt_modal.note" defaultMessage="You can enter additional email addresses(comma separated) to receive the notification email after the search completes." />
              </span>
            </p>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { source } = this.props;
    const { stepNo, isProcessCompleted } = this.state;

    return (
      <div>

        {(isProcessCompleted === true) ? this._renderMessage() : (
          <>
            <div className="mt-6 my-4">
              <h1 className="heading-02 fw-medium">
                {((stepNo === 1) && (source === "contact")) && <FormattedMessage id="enrich_contact.title" defaultMessage="Enrich - Contact" />}
                {((stepNo === 2) && (source === "contact")) && <FormattedMessage id="enrich_contact.title_mapping" defaultMessage="Enrich - Mapping" />}
                {((stepNo === 3) && (source === "contact")) && <FormattedMessage id="enrich_contact.title_data" defaultMessage="Enrich - Data" />}

                {((stepNo === 1) && (source === "company")) && <FormattedMessage id="enrich_company.title" defaultMessage="Enrich - Company" />}
                {((stepNo === 2) && (source === "company")) && <FormattedMessage id="enrich_company.title_mapping" defaultMessage="Enrich - Mapping" />}
                {((stepNo === 3) && (source === "company")) && <FormattedMessage id="enrich_company.title_data" defaultMessage="Enrich - Data" />}

                {((stepNo === 1) && (source === "company_employees")) && <FormattedMessage id="enrich_company_employees.title" defaultMessage="Enrich - Company Employees" />}
                {((stepNo === 2) && (source === "company_employees")) && <FormattedMessage id="enrich_company_employees.title_mapping" defaultMessage="Enrich - Mapping" />}
                {((stepNo === 3) && (source === "company_employees")) && <FormattedMessage id="enrich_company_employees.title_data" defaultMessage="Enrich - Data" />}
              </h1>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="bg-white border p-6 rounded-4 mb-5">
                  <h2 className="heading-04 fw-medium mb-8">Upload your leads file</h2>
                  <div className="row">
                    <div className="step-bar col-lg-12">
                      <div className="step-bar-line">
                        {/*<div role="progressbar" style={{ width: (stepNo === 1) ? "0%" : ((stepNo === 2) ? "50%" : "100%") }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>*/}
                        <div className="step-bar-line-back" />
                        <div className="step-bar-line-front" style={{ width: (stepNo === 1) ? "0%" : ((stepNo === 2) ? "50%" : "100%") }} />
                      </div>
                      <ul className="list-unstyled">
                        <li className={`text-capitalize ${(stepNo >= 1) ? "active" : ""}`}>
                          <div className="step-bar-dot" to="/#" onClick={(e) => { e.preventDefault(); this.setState({ stepNo: 1 }) }} />
                          <div className="step-bar-text">
                            <FormattedMessage id="enrich_contact.file_upload" defaultMessage="File Upload" /> <br />
                            <FormattedMessage id="enrich_contact.upload_your_leads_file" defaultMessage="Upload your leads file" />
                          </div>
                        </li>
                        <li className={`text-capitalize ${(stepNo >= 2) ? "active" : ""}`}>
                          <div className="step-bar-dot" to="/#" onClick={(e) => { e.preventDefault(); this._handleNext(2) }} />
                          <div className="step-bar-text">
                            <FormattedMessage id="enrich_contact.mapping" defaultMessage="Mapping" /> <br />
                            <FormattedMessage id="enrich_contact.map_your_columns" defaultMessage="map your columns" />
                          </div>
                        </li>
                        <li className={`text-capitalize ${(stepNo >= 3) ? "active" : ""}`}>
                          <div className="step-bar-dot" to="/#" onClick={(e) => { e.preventDefault(); this._handleNext(3) }} />
                          <div className="step-bar-text">
                            <FormattedMessage id="enrich_contact.data" defaultMessage="data" /> <br />
                            <FormattedMessage id="enrich_contact.add_data" defaultMessage="add data" />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {(stepNo === 1) && this._renderStep1()}
                  {(stepNo === 2) && this._renderStep2()}
                  {(stepNo === 3) && this._renderStep3()}
                </div>
              </div>
            </div>
          </>
        )}

        {this._renderFailedDataModal()}
        {this._renderWorksheetSelection()}
        {this._renderSendReceiptModal()}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  userEmail: _get(state, "oauth.user.email", ""),
  mappingData: _get(state, "enrich.fileUpload.dataMapping.data", {}),
  success: _get(state, "enrich.fileUpload.dataMapping.success", {}),
  workSheets: _get(state, "enrich.workSheets", []),
  successMessage: _get(state, "enrich.fileUpload.dataMapping.successMessage", ""),
  removeUpload: _get(state, "enrich.removeUpload", {}),
  errors: _get(state, "enrich.fileUpload.errors", {}),
  userDetails: _get(state, "application.settings.data", {}),
  remainingCredits: _get(state, "application.creditDetails.data.remaining_credits", 0),
  data_source: _get(state, "enrich.fields.data_fields", []),
  ccEmails: _get(state, "enrich.fields.cc_emails", []),
});

const mapDispatchToProps = (dispatch) => ({
  combinedLookupData: (data) => dispatch(combinedLookupData(data)),
  uploadReadFileSuccess: (data) => dispatch(uploadReadFileSuccess(data)),
  uploadReadFileError: (data) => dispatch(uploadReadFileError(data)),
  saveUploadMappingData: (data) => dispatch(saveUploadMappingData(data)),
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
  removeUploadedFile: (data) => dispatch(removeUploadedFile(data)),
  resetRemoveUploadedFile: () => dispatch(resetRemoveUploadedFile()),
  resetDataMappingError: () => dispatch(resetDataMappingError()),
  resetDataMappingConfig: () => dispatch(resetDataMappingConfig()),
  fetchUserSettings: () => dispatch(fetchUserSettings()),
  fetchCreditDetails: () => dispatch(fetchCreditDetails()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(SingleDropzone)));