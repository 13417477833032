import React, { Component } from "react";
import { get as _get, cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty } from "lodash";
import { injectIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";

import { showAlertMessage, Select, Modal, ErrorMessage } from "../../../components/common/controls";
import { withRouter } from "../../../components/hoc/withRouter";

import { editUserDetails, getUserDetailsById } from "../../../services/userManagement";

import { updateLoadingState } from "../../../actions/application";

const rolesData = [{ id: 1, name: "Admin" }, { id: 2, name: "Client" }];

class EditUser extends Component {

  constructor(props) {
    super(props);

    this.defaultEditUser = {
      firstName: "",
      lastName: "",
      email: "",
      roleId: null,
      clientId: null,
      selRoleObj: {},
      selClientObj: {}
    };

    this.state = {
      editUser: _cloneDeep(this.defaultEditUser),
      errors: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.editUser, _get(this.state, "editUser", {}))) { this._handleValidation(); }

    if (!_isEqual(prevProps.showEditModal, _get(this.props, "showEditModal", false)) && (_get(this.props, "showEditModal", false) === true)) {
      this._fetchUserDetails();
    }
  }

  _closeModal = () => {
    const { setState } = this.props;

    if (typeof setState === "function") { setState({ showEditModal: false, selRecord: {} }) }

    this.setState({ editUser: _cloneDeep(this.defaultEditUser) });
  }

  _fetchUserDetails = async () => {
    const { updateLoadingState, selRecord } = this.props;

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await getUserDetailsById({ user_id: _get(selRecord, "user_id", 0) });

      let userDetails = _get(response, "data", {});

      this.setState((prevState) => ({
        editUser: {
          ...prevState.editUser,
          firstName: _get(userDetails, "first_name", ""),
          lastName: _get(userDetails, "last_name", ""),
          email: _get(userDetails, "email", ""),
          roleId: _get(userDetails, "role_id", null),
          clientId: _get(userDetails, "client_id", null),
          selRoleObj: { id: _get(userDetails, "role_id", ""), name: _get(userDetails, "role", "") },
          selClientObj: { client_id: _get(userDetails, "client_id", ""), client_name: _get(userDetails, "client_name", "") }
        }
      }));

    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while fetching user details.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _handleValidation = (returnFlag = false) => {
    const { intl } = this.props;
    const { editUser: { firstName, lastName, clientId, roleId } } = this.state;
    let errors = {};

    if (_isEmpty(firstName)) {
      errors["firstName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "first_name", defaultMessage: "first_name" }),
      });
    }

    if (_isEmpty(lastName)) {
      errors["lastName"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "last_name", defaultMessage: "last_name" }),
      });
    }

    if (!clientId) {
      errors["clientId"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "client", defaultMessage: "Client" }),
      });
    }

    if (!roleId) {
      errors["roleId"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "role", defaultMessage: "Role" }),
      });
    }

    if (returnFlag === true) { return !_isEmpty(errors); }

    this.setState({ errors });
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      editUser: {
        ...prevState.editUser,
        [name]: value
      }
    }));
  }

  _handleEditUser = async () => {
    const { updateLoadingState, selRecord, setState } = this.props;
    const { editUser: { firstName, lastName, roleId, clientId, email }, errors } = this.state;

    if (_isEmpty(firstName) || _isEmpty(lastName) || (!roleId) || (!clientId) || !_isEmpty(errors)) {
      this._handleValidation();

      return;
    }

    try {
      if (typeof updateLoadingState === "function") { updateLoadingState(true); }

      const response = await editUserDetails({
        user_id: _get(selRecord, "user_id", 0),
        role_id: (roleId || 0),
        client_id: (clientId || 0),
        first_name: firstName,
        last_name: lastName,
        email: (email || ""),
      });

      if (_get(response, "data.status", false) === true) {
        showAlertMessage(_get(response, "data.message", "User details updated successfully."), "success");

        if (typeof setState === "function") { setState({ selRecord: {}, showEditModal: false, reSyncTableData: true }); }

        this.setState({ editUser: _cloneDeep(this.defaultEditUser) });

      } else {
        showAlertMessage(_get(response, "message", "Something went wrong while updating user details."));
      }
    } catch (err) {
      showAlertMessage(err.message || "Something went wrong while fetching updating user details.");
    } finally {
      if (typeof updateLoadingState === "function") { updateLoadingState(false); }
    }
  }

  _renderEditUser = () => {
    const { showEditModal, clientList, intl } = this.props;
    const { editUser: { firstName, lastName, email, selClientObj, selRoleObj }, errors } = this.state;

    let updatedClientList = _cloneDeep(clientList);
    (updatedClientList || []).shift();

    return (
      <Modal
        size="lg"
        centered={true}
        footerClassName=""
        showCloseButton={true}
        isOpen={(showEditModal || false)}
        modalTitle={intl.formatMessage({ id: "user_management.edit_user.title", defaultMessage: "Edit User" })}
        onHide={() => this._closeModal()}
        onClose={() => this._closeModal()}
        onSubmit={() => this._handleEditUser()}
        closeBtnText={intl.formatMessage({ id: "btn.close", defaultMessage: "close" })}
        submitBtnText={intl.formatMessage({ id: "btn.submit", defaultMessage: "submit" })}
      >
        <div className="section-create-user">
          <div className="container">
            <div className="row">
              <Form autoComplete="off">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <input type="text" name="firstName" value={(firstName || "")} className="form-control" placeholder="First Name" onChange={(e) => this._handleChange(e)} />
                      {(errors.firstName || "") && (
                        <div className="mt-1" >
                          <ErrorMessage message={(errors.firstName || "")} />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-3">
                      <input type="text" name="lastName" className="form-control" value={(lastName || "")} placeholder="Last Name" onChange={(e) => this._handleChange(e)} />
                      {(errors.lastName || "") && (
                        <div className="mt-1" >
                          <ErrorMessage message={(errors.lastName || "")} />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-3">
                      <input type="email" name="email" className="form-control" value={(email || "")} placeholder="Email" onChange={(e) => this._handleChange(e)} />
                      {(errors.email || "") && (
                        <div className="mt-1" >
                          <ErrorMessage message={(errors.email || "")} />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-3">
                      <Select
                        className="text-capitalize form-select-custom mb-1"
                        placeholder={intl.formatMessage({ id: "client", defaultMessage: "Client" })}
                        value={(selClientObj || null)}
                        options={(updatedClientList || [])}
                        getOptionLabel={(option) => (option.client_name || "")}
                        getOptionValue={(option) => (option.client_id || null)}
                        isOptionDisabled={(option) => (option.isdisabled || false)}
                        onChange={(y) => this.setState((prevState) => ({
                          editUser: {
                            ...prevState.editUser,
                            clientId: _get(y, "client_id", null),
                            selClientObj: (y || {}),
                          }
                        }))}
                        error={(errors.clientId || "")}
                        isMulti={false}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-3">
                      <Select
                        className="text-capitalize form-select-custom mb-1"
                        placeholder={intl.formatMessage({ id: "role", defaultMessage: "Role" })}
                        value={(selRoleObj || null)}
                        options={(rolesData || [])}
                        getOptionLabel={(option) => (option.name || "")}
                        getOptionValue={(option) => (option.id || null)}
                        onChange={(y) => this.setState((prevState) => ({
                          editUser: {
                            ...prevState.editUser,
                            roleId: _get(y, "id", null),
                            selRoleObj: (y || {}),
                          }
                        }))}
                        error={(errors.roleId || "")}
                        isMulti={false}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { showEditModal } = this.props;

    return (
      <>

        {(showEditModal === true) && this._renderEditUser()}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLoadingState: (data) => dispatch(updateLoadingState(data)),
});

export default withRouter(connect(null, mapDispatchToProps)(injectIntl(EditUser)));